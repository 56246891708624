
export default function searchCEP() {
	
	const cepInputs = document.querySelectorAll('[js-cep-search]')
	const cepItems = document.querySelectorAll('[js-cep-search-item]')
	// console.log(cepItems)

	
	function buscaCEP(cepInput) {
		// só busca quando a quantidade de caracteres cooresponde a 9, que respeita o formato 66666-888
		if (cepInput.value.length === 9) {
			const url = `https://brasilapi.com.br/api/cep/v2/${cepInput.value}`
			//const url = `https://viacep.com.br/ws/${cepInput.value}/json`
			axios.get(url)
				.then(({ data }) => {
					setFields(data)
					
				})
				.catch(({ response }) => {
					unsetFields()
					const errorMessage = response.data.errors[0].message
					GLOBAL.showToastify(errorMessage)
				})
		} else {
			unsetFields()
			cepInput.value = ''
		}
	}

	cepInputs.forEach(cepInput => {
		const cepIsValid = () => cepInput.value.length > 8
		
		cepInput.addEventListener('input', () => {	
			// console.log('AQUUIIIII')
			if(!cepIsValid()){		
				return
			}
		
			buscaCEP(cepInput)
		})
		cepInput.addEventListener('focusout', () => {	
			if(!cepIsValid()){		
				return
			}
			buscaCEP(cepInput)
		})
	
	})

	
	

	function setFields(data) {

		const { street, neighborhood, city, state, location } = data

		cepItems.forEach(item => {
			const attr = item.getAttribute('js-cep-search-item')
			let valueData = data[attr.toString()]
	
			switch (item.name) {
				case 'cidade':
					item.value = city
					break;
				case 'rua':
					item.value = street || ''
					break;
				case 'estado':
					item.value = state
					break;
				case 'bairro':
					item.value = neighborhood || ''
					break;
				case 'latitude':
					item.value = location.coordinates.latitude || ''
					break;
				case 'longitude':
					item.value = location.coordinates.longitude || ''
					break;
				default:
					break;
			}
		
			if (valueData !== undefined) {
				setAndToggleEnable(item, valueData, toggle = false)
			}

		})
	}

	function unsetFields() {
		
		cepItems.forEach(item => {
				item.value = ""
			
		})

	}
}