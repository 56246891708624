export default function tableFluxoColorNumber() {
	const attr = "js-table-color"
	const els = document.querySelectorAll(`[${attr}]`)
	// console.log(els)


	if (!els.length) return


	els.forEach(i => {
		const paragrafh = i.querySelector('.td-container')
		if (i.classList.contains('acrescimo')) {
			paragrafh.style.color = '#1CC066'
		} else if (i.classList.contains('desconto')) {
			paragrafh.style.color = '#EC3539'
		}

	})

}