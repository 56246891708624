export default function modalFiltrar(wrapperBadge, wrapperFields) {
	const attr = document.querySelector("[js-modal-filtrar]");

	if (!attr) return;

	const token = "csrfmiddlewaretoken";

	//array de itens que não devem aparecer na div de filtros ativos
	const arrItens = ["order", "page", "paginate_by", "filter"];

	const ativos =
		"<p class='flex text-sm gap-8 font-semibold text-neutral-80'><svg class='w-20 h-20 text-primary-pure'><use xlink:href='#icon_filtrar'></use></svg>Filtros Ativos</p>";
	const btnLimpar = document.querySelector("[js-limpar-filtro]");

	const geraBadge = (item) => `<div class="badge">${item}</div>`;

	function filtrosAtivos(wrapper) {
		const url = new URL(window.location);
		// const urlSearchParams = new URLSearchParams(url);
		// const params = Object.fromEntries(urlSearchParams.entries());
		// const array = Object.entries(params);
		// console.log(params)
		let filtersOBJ = GLOBAL.splitFilters(url.search);
		// console.log(url.search)
		//  console.log('obj', filtersOBJ)
		if (!filtersOBJ) return;

		const filterArray = Object.entries(filtersOBJ);
		// console.log(filterArray)
		const template = filterArray
			.filter(
				([key, value]) => value && key != token && !arrItens.includes(key)
			)
			.map(([key, value]) => geraBadge(key.replaceAll("_", " ")));

		const badges = `${ativos}<div class="flex gap-8 flex-wrap">${template.join(
			" "
		)}</div>`;
		// console.log(wrapperBadge, 'aq,', template)
		if (template.length) {
			btnLimpar?.classList.remove("hidden");
			if (wrapperBadge) {
				wrapperBadge.classList.add("flex");
				wrapperBadge.classList.remove("hidden");
				wrapperBadge.innerHTML = badges;
			}
			setValueOnFields(wrapper, filtersOBJ);
		}
	}

	function setValueOnFields(wrapper, obj) {
		const objArr = Object.entries(obj);

		objArr.forEach(([key, value]) => {
			const field = wrapperFields.querySelector(`[name="${key}"]`);
			// console.log('field', field)
			if (!field) return;

			const type = field.tagName;

			if (type === "SELECT") {
				const select = GLOBAL.getChoicesActive(field.id, listOfChoices);
				select.setChoiceByValue(value);
			}
			if (type === "INPUT") {
				// const str = "ç_--áäàãa@! teste}{\ ";
				field.value = decodeURIComponent(value.replaceAll(/\+/g, " "));
			}
		});
	}

	function limparFiltros() {
		const url = new URL(window.location);
		// console.log(url.pathname)
		let filtersOBJ = GLOBAL.splitFilters(url.search);
		let newUrl = `${url.origin}${url.pathname}${url.hash}`;
		//não apaga o filtro de tipo que é adicionado ao escolher a tab e não em um campo do modal
		/* if(filtersOBJ.tipo){
			newUrl += `?tipo=${filtersOBJ.tipo}`
		} */
		wrapperBadge.classList.remove("flex");
		wrapperBadge.classList.add("hidden");
		window.location.href = newUrl;
	}

	function init() {
		document.addEventListener("DOMContentLoaded", () =>
			filtrosAtivos(wrapperBadge)
		);
		btnLimpar.addEventListener("click", limparFiltros);
	}

	return {
		init,
	};
}
