export default function verifyAndSubmitDelete() {
	const allForms = document.querySelectorAll('form')
	allForms.forEach(form => {
		form.addEventListener('submit', e => {
			const isDelete = form.getAttribute('method').toLowerCase() === 'delete'
			if (isDelete) {
				e.preventDefault()
				axios.post(form.getAttribute('action'), {
					...new FormData(form),
					redirect: form.getAttribute('data-redirect')
				})
					.then(({ data }) => {
						GLOBAL.verifyHaveError(data)
						data.message && GLOBAL.showToastify(data.message)
						if(data.reload) {
							setTimeout(() => {
								window.location.reload()
							}, 500)
						}
						if(data.redirect) {
							setTimeout(() => {
								window.location.href = data.redirect
							}, 500)
						}
					}).catch(error => {
						GLOBAL.showToastify(error)
					})
			}
		})
	})
}