export default function numberRed(){
    const numbers = document.querySelectorAll('[js-number]')

    numbers.forEach(number => {
        const str = number.textContent.replace('.', '').replace(',', '.')
        const value = Number(str)
       
        if(value < 0){
            number.style.color = '#EC3539'
            const container = number.closest('[js-container-number]')
            if(!container) return
            container.style.color = '#EC3539'
        }
    })
}