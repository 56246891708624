export default function FilepondCardCreatorWrapper(){
    let numeroAnexos = 0
	function FilepondCardsCreator(obj) {

		const { containerCards, tabsSeletor, haveTabs, contentClass, tipoProduto } = obj

		const content = document.querySelector(contentClass)

		const produtoContainer = tipoProduto ? content.closest(".modalX") : null
		const modaldata = () => tipoProduto ? produtoContainer.getAttribute("data-modaldata") : null
		const containerProduto = tipoProduto ? getContainerProduto() : null
		
		const container = containerProduto || document.querySelector(containerCards)
		const inputs = content.querySelectorAll('input')
		// console.log(inputs, 'inputs file')
		
		const  setNameInInput = (input, name) => input.name = name




		function getContainerProduto() {
			const obj = JSON.parse(modaldata())
			const container = document.querySelector(`#${obj.inputSeletor}`)
			return container.closest(".inline-item").querySelector(".wrapper-anexos")

		}

	

		function createCards() {


			inputs.forEach((input) => {

				if (haveTabs) {
					const parent = input.closest('.tab-collapse')
					const categoriaName = parent.getAttribute('data-name')
					const categoriaID = parent.getAttribute('data-idcategoria')
					// console.log(categoriaName,'name')
					const selectParent = parent.closest('select')
					const arrayFiles = Object.values(input.files);

					const select = tipoProduto ? 
						getContainerProduto().closest('.inline-item').querySelector('select') : 
						null

					
					const template = arrayFiles
						.map(file => geraTemplateAnexo({ name: file.name, categoriaName: categoriaName }))
						.map(div => {
							const clone = input.cloneNode(true)
							clone.classList.add('invisible')

							if(tipoProduto){
								const produtoID = GLOBAL.getChoicesActive(select.id, listOfChoices).getValue().value
								setNameInInput(clone, `categoria_item-${categoriaID}-${produtoID}-${numeroAnexos}`)
							}else{
								setNameInInput(clone, `categoria-${categoriaID}-${numeroAnexos}`)
							}


							div.appendChild(clone)
							numeroAnexos++
							return div
						})

					template
						.map(i => tipoProduto ? getContainerProduto().appendChild(i) : container.appendChild(i))


				} else {
					const arrayFiles = Object.values(input.files);
					const template = arrayFiles
						.map(file => geraTemplateAnexo({ name: file.name }))
						.map(div => {
							const clone = input.cloneNode(true)
							// console.log('clone', clone)
							div.appendChild(clone)
							return div
						})


					if (tipoProduto) {
						template.map(i => getContainerProduto().appendChild(i))
						return
					}

					template.map(i => container.appendChild(i))


				}


			})


			//removendo os items do filepond depois de popular 
			filepondList.forEach(f => f.removeFiles())

		}

		function geraTemplateAnexo({ name, categoriaName }) {
			const div = document.createElement("div")
			div.classList.add("container-anexo-item","w-full")
		
			div.innerHTML = `
				<div
					class="anexo-item  flex flex-col bg-white min-w-[16.25rem] w-full h-[7.25rem] border-[.0938rem] border-neutral-30 shadow-xs rounded relative shrink-0">
					<div class="w-full m-0 h-40 bg-primary-10 rounded-t flex justify-end items-center">
						<div  onclick="this.closest('.container-anexo-item').remove()"  class="close !w-24 !h-24 rounded-full bg-alert-red-10 shrink-0  cursor-pointer transition-transform hover:scale-110 grid place-items-center mr-8">
							<svg class="text-alert-red-100 !w-12 !h-12">
								<use xlink:href="#icon_close"></use>
							</svg>
						</div>
					</div>
					<svg class="w-32 h-32 absolute top-24 left-8">
						<use xlink:href="#icon_file"></use>
					</svg>
					<div class="flex flex-col mx-14 mt-24">
						<h1 class="text-neutral-80 text-sm font-semibold max-1-lines">${categoriaName}</h1>
						<p class="text-neutral-70 text-xs font-medium max-2-lines">${name}</p>
					</div>
					
					
				</div>
			`
		return div
	}

		return {
			createCards
		}
	}
    window.FilepondCardsCreator = FilepondCardsCreator
}