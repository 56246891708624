export default function globalAnimations() {
	const axis = {
		x: { x: 0, },
		y: { 	y: 0, },
	};

	function animateSideBar() {
		const sideBarSelector = ".sidebar > ul > li, .sidebar > a ";
		const sideBar = document.querySelector(sideBarSelector);

		sideBar &&
			gsap.set(sideBarSelector, {
				opacity: 0,
				y: 50,
				transition: "initial",
			});

		sideBar &&
			document.addEventListener("DOMContentLoaded", () => {
				defaultAnimation(sideBarSelector, axis.y);
			});
	}

	function animateCardTotalizador(seletor) {
		defaultAnimation(seletor, axis.y);
	}

	function animateBreadcrumb() {
		const breadcrumbSelector = ".breadcrumb-wrapper li";
		const breadcrumbs = document.querySelectorAll(breadcrumbSelector);

		const tl = gsap.timeline();
		breadcrumbs.forEach((breadcrumb) => animateItemBreadcrumb(breadcrumb));

		function animateItemBreadcrumb(breadcrumb) {
			tl.from(breadcrumb, {
				x: -50,
				opacity: 0,
				duration: 0.3,
			});
		}
	}

	function animateRightButtons() {
		const rightButtonsSelector = "[right-buttons]";
		const rightButtons = document.querySelector(rightButtonsSelector);

		rightButtons &&
			gsap.set(rightButtonsSelector, {
				opacity: 0,
				x: 50,
				transition: "initial",
			});

		rightButtons &&
			document.addEventListener("DOMContentLoaded", () => {
				defaultAnimation(rightButtonsSelector, axis.x);
			});
	}

	function animateMainContent() {
		const mainContentSelector = ".container-content > *";
		const mainContent = document.querySelector(mainContentSelector);

		mainContent &&
			gsap.set(mainContentSelector, {
				opacity: 0,
				y: 50,
				transition: "initial",
			});

		mainContent &&
			document.addEventListener("DOMContentLoaded", () => {
				defaultAnimation(mainContentSelector, axis.y);
			});
	}

	function defaultAnimation(selector, axis) {
		gsap.to(selector, {
			opacity: 1,
			stagger: 0.05,
			ease: "sine",
			clearProps: "all",
			...axis,
		});
	}

	(function init() {

		// animateSideBar();
		// animateBreadcrumb();
		// animateRightButtons();
		// animateMainContent();
		// animateCardTotalizador();
	})();
}
