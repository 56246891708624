export default function tableSelecionarMultiColunas() {
	const attr = "[js-selecionar-multi-colunas] select";
	const els = document.querySelectorAll(attr);
	if (!els.length) return;

	const url = new URL(window.location);
	let filtersOBJ = GLOBAL.splitFilters(url.search) || {};
	let colunasOcultas = [];

	if (filtersOBJ) {
		if (filtersOBJ["colunas_ocultas"]) {
			colunasOcultas = filtersOBJ["colunas_ocultas"].split(",");
		}
	}

	const showHideColun = (value, hidden = true) => {
		const table = document.querySelector("table");
		const tableColuns = table.querySelectorAll(`[data-reference='${value}']`);

		if (hidden) {
			if (!colunasOcultas.includes(value)) {
				colunasOcultas.push(value);
			}
			tableColuns.forEach((i) => i.classList.add("hidden"));
		} else {
			colunasOcultas = colunasOcultas.filter((i) => i !== value);
			tableColuns.forEach((i) => i.classList.remove("hidden"));
		}
		filtersOBJ["colunas_ocultas"] = colunasOcultas;

		const newFilters = GLOBAL.generateStringMultiFilterFromObject(filtersOBJ);
		history.pushState(null, null, `${url.origin}${url.pathname}${newFilters}`);
	};

	els.forEach((select) => {
		$(select).multiSelect({
			afterSelect: function (values) {
				values.forEach((i) => showHideColun(i));
			},
			afterDeselect: function (values) {
				values.forEach((i) => showHideColun(i, (hidden = false)));
			},
		});
		$(select).multiSelect("select", colunasOcultas);
	});
}
