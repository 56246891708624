export default function mutationForm() {
    const forms = document.querySelectorAll('[js-mutation-observer]')

    forms.forEach(form => {
        const selects = form.querySelectorAll('select')
        const inputs = form.querySelectorAll('inputs')
        //alterando atributo de required para data-required
        function requiredData() {

            // console.log(selects, ' selects')
            selects.forEach(select => {

                if (select.required) {
                    select.setAttribute('data-required', true)
                    select.removeAttribute('required')
                }
            })

            inputs.forEach(input => {

                if (input.required) {
                    input.setAttribute('data-required', true)
                    input.removeAttribute('required')
                }
            })
        }

        //callback do observer
        function callbackMutation(mutations) {
            for (let mutation of mutations) {
                if (mutation.type === 'childList') {
                    // console.log(mutation, 'mutation');
                }
            }
        }

        let observer = new MutationObserver(callbackMutation);

        // console.log(form)
        //Observando mudança nos filhos do form
        observer.observe(form, {
            childList: true

        });


        //verfifica se existe select com o attr data-required vazio
        function isInvalidSelect() {
            let isVazio = false
            selects.forEach(select => {
                // console.log(select, select.value)
                if (select.getAttribute('data-required') && select.value == '') {
                    isVazio = true
                }
            })
            return isVazio
        }

        function isInvalidInput() {
            let isVazio = false
            inputs.forEach(input => {
                if (input.getAttribute('data-required') && input.value == '') {
                    isVazio = true
                }
            })
            return isVazio
        }

        //identifica mudanças no form pra alterar attr de possíveis novos selects
        form.addEventListener('change', () => {
            requiredData()
        })


        function submit() {
            form.addEventListener('submit', (e) => {

                if (!e.submitter.classList.contains('voltar')) {

                    if (isInvalidSelect() || isInvalidInput()) {
                        e.preventDefault()
                        // console.log('aquii')
                        GLOBAL.showToastify('Preencha todos os campos obrigatórios para seguir')
                    }
                }

                observer.disconnect();
            })
        }

        function init() {
            requiredData()
            submit()
        }

        init()
    })

}