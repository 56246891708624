export default function addDisabledSelectContainer() {
	const selects = document.querySelectorAll('js-choices')
	selects.forEach(select => {
		if (select.classList.contains('disabled')) {
			const container = select.closest('.select-container')
			if (container) {
				container.classList.add('disabled')
			} else {
				//caso o select nao esteja no padrão do design
				const containerNew = document.createElement('div')
				containerNew.classList.add('select-container', 'h-48', 'disabled')

				const divGeralSelect = select.closest('.choices').parentNode
				divGeralSelect.append(containerNew)
				//coloca o select p/ div criada 
				containerNew.append(select.closest('.choices'))
			}
		}

	})
}