export default function FilepondCardCreatorItemWrapper() {
	let numeroAnexos = 0
	function FilepondCardsCreatorItem(obj) {

		const { containerCards, tabsSeletor, haveTabs, contentClass, tipoProduto } = obj

		const content = document.querySelector(contentClass)
		// console.log(content, 'content', tipoProduto)
		const produtoContainer = tipoProduto ? content.closest(".modalX") : null
		const modaldata = () => tipoProduto ? produtoContainer.getAttribute("data-modaldata") : null
		const containerProduto = tipoProduto ? getContainerProduto() : null

		// console.log(document.querySelector(containerCards), 'AQUI')

		const container = containerProduto || document.querySelector(containerCards)
		const inputs = content.querySelectorAll('input')


		const setNameInInput = (input, name) => input.name = name



		function getContainerProduto() {
			const obj = JSON.parse(modaldata())
			// console.log(obj, 'obj')
			const container = document.querySelector(`#${obj.inputSeletor}`)

			return container.querySelector(".wrapper-anexos")

		}



		function createCards() {


			inputs.forEach((input) => {

				if (haveTabs) {
					const parent = input.closest('.tab-collapse')
					// console.log(parent, 'parent')
					const categoriaName = parent.getAttribute('data-name')
					const categoriaID = parent.getAttribute('data-idcategoria')
					// console.log(categoriaName, 'name')
					// console.log(categoriaID, 'categoriaId')
					const arrayFiles = Object.values(input.files);


					const template = arrayFiles
						.map(file => geraTemplateAnexo({ name: file.name, categoriaName: categoriaName }))
						.map(div => {
							const clone = input.cloneNode(true)
							clone.classList.add('invisible')

							if (tipoProduto) {
								//const produtoID = GLOBAL.getChoicesActive(select.id, listOfChoices).getValue().value
								const produtoID = containerProduto.closest('.accordion-item').getAttribute('js-id-prod')

								setNameInInput(clone, `categoria_item-${categoriaID}-${produtoID}-${numeroAnexos}`)
							} else {
								setNameInInput(clone, `categoria-${categoriaID}-${numeroAnexos}`)
								// console.log(clone.name, 'clone')
							}



							div.appendChild(clone)
							numeroAnexos++
							return div
						})

					template
						.map(i => tipoProduto ? getContainerProduto().appendChild(i) : container.appendChild(i))


				} else {
					const arrayFiles = Object.values(input.files);
					const template = arrayFiles
						.map(file => geraTemplateAnexo({ name: file.name }))
						.map(div => {
							const clone = input.cloneNode(true)
							// console.log('clone', clone)
							div.appendChild(clone)
							return div
						})


					if (tipoProduto) {
						template.map(i => getContainerProduto().appendChild(i))
						return
					}

					template.map(i => container.appendChild(i))


				}


			})


			//removendo os items do filepond depois de popular 
			filepondList.forEach(f => f.removeFiles())

		}

		function geraTemplateAnexo({ name, categoriaName }) {
			const div = document.createElement("div")
			div.classList.add("container-anexo-item", "w-full")

			div.innerHTML = `
				<div
					class="anexo-item  flex flex-col bg-white min-w-[16.25rem] w-full h-[7.25rem] border-[.0938rem] border-neutral-30 shadow-xs rounded relative shrink-0">
					<div class="w-full m-0 h-40 bg-primary-10 rounded-t flex justify-end items-center">
						<div  onclick="this.closest('.container-anexo-item').remove()"  class="close !w-24 !h-24 rounded-full bg-alert-red-10 shrink-0  cursor-pointer transition-transform hover:scale-110 grid place-items-center mr-8">
							<svg class="text-alert-red-100 !w-12 !h-12">
								<use xlink:href="#icon_close"></use>
							</svg>
						</div>
					</div>
					<svg class="w-32 h-32 absolute top-24 left-8">
						<use xlink:href="#icon_file"></use>
					</svg>
					<div class="flex flex-col mx-14 mt-24">
						<h1 class="text-neutral-80 text-sm font-semibold max-1-lines">${categoriaName}</h1>
						<p class="text-neutral-70 text-xs font-medium max-2-lines">${name}</p>
					</div>
					
					
				</div>
			`
			return div
		}

		return {
			createCards
		}
	}
	window.FilepondCardsCreatorItem = FilepondCardsCreatorItem
}