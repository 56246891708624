export default function applyMaskMoney() {
	const valoresUnitarios = document.querySelectorAll("[js-money-mask]")
	valoresUnitarios?.forEach((item) => {
		item.setAttribute("type", "text")
		$(`#${item.id}`).maskMoney({
			thousands: '.',
			decimal: ',',
			allowNegative: true,
		})
		if (item.value && !item.value.includes(",")) item.value = GLOBAL.fMoney(item.value).replace("R$", "").trim()
	})
}